<template>
<v-app>
       <SingleAppBar title="ยืนยันการนัดหมาย" :backward="false"/>
  <v-main class="natural lighten-3">
      <v-container fluid class="pl-0 pr-0 fill-height" style="align-items: start;">
        <v-row>
<v-col cols="12">
  <v-card tile elevation="0" color="transparent">
    <v-card-text class="text-center">
     <v-row class="pt-10">
       <v-col cols="12">
           <v-avatar size="126" tile>
          <v-img :src="require('@/assets/Complete.svg')"/>
        </v-avatar>
       </v-col>
       <v-col cols="12">
          <div class="pt-10 text-h5 natural--text text--darken-2">ทำการยกเลิกนัดสำเร็จ</div>
        <!-- <div class="pt-10 text-h5 natural--text text--darken-4">วันที่ {{ $dayjs($route.params.perDate).local($i18n.locale).format('DD MMMM YYYY HH:mm')  }}</div>
      <div class="text-h5 natural--text text--darken-4">แพทย์ {{$route.params.userFullname}}</div>
      <div class="text-h5 natural--text text--darken-4">{{$route.params.department}}</div> -->
       </v-col>
              </v-row>
    </v-card-text>
  </v-card>
</v-col>
        </v-row>
      </v-container>
  </v-main>
  <v-footer>
      <v-row>
         <v-col cols="12" class="pt-2">
                   <!-- <v-btn block large outlined color="primary" target="_blank" :href="calendar" ><span class="text-title-2">ดาว์โหลดปฏิทิน</span></v-btn> -->
                </v-col>
           <v-col cols="12" class="pt-2">
                   <v-btn block large outlined color="primary" :to="{name: 'appointmenthistory'}"><span class="text-title-2">เปลี่ยนแปลงนัดหมาย</span></v-btn>
                </v-col>
                 <v-col cols="12" class="pt-0">
                   <v-btn block large color="primary text-title-2" :to="{name: 'main'}">เสร็จสิ้น</v-btn>
                </v-col>
                 <v-col cols="12" class="pt-0"></v-col>
      </v-row>
  </v-footer>
</v-app>
</template>

<script>
import SingleAppBar from "@/components/common/SingleAppBar";
import {mask} from 'vue-the-mask'
// import { saveAs } from 'file-saver'
export default {
components: {
 SingleAppBar,
  // TextField,
  // TextArea,
},
directives: {mask},
data() {
  return {
          calendar: null,
          event: {
  start: [2021, 7, 30, 6, 30],
  duration: { hours: 6, minutes: 30 },
  subject: 'นัดพบแพทย์',
  description: '',
  location: '',
  url: 'https://phr.vwell.care/',
  organizer: { name: 'VWELL', email: 'vwellsupport@scg.com' }
}
  }
},
mounted () {
  this.downloadCalendar();
},
methods: {
  async downloadCalendar() {
    const site = this.$offlineStorage.get("site");
    this.event.location = site.siteName
    this.event.description = 'คุณมีนัดพบแพทย์ ' + this.$route.params.userFullname + 'สาขา '+ this.$route.params.department
    this.$ics.removeAllEvents()
    // this.$dayjs(this.$route.params.perDate)
    console.log(this.$dayjs(this.$route.params.perDate).utc(true).format())
    this.$ics.addEvent('en-TH', this.event.subject, this.event.description, this.event.location, this.$dayjs(this.$route.params.perDate), this.$dayjs(this.$route.params.perDate), this.event.url, this.event.organizer)
    let cal = this.$ics.calendar()
    //console.log(this.calendar)
    //console.log('data:text/calendar;charset=utf8,BEGIN:VCALENDAR%0AVERSION:2.0%0ABEGIN:VEVENT%0AURL:http://carlsednaoui.github.io/add-to-calendar-buttons/example.html%0ADTSTART:20130615T120000Z%0ADTEND:20130615T160000Z%0ASUMMARY:Get%20on%20the%20front%20page%20of%20HN%0ADESCRIPTION:Get%20on%20the%20front%20page%20of%20HN,%20then%20prepare%20for%20world%20domination.%0ALOCATION:The%20internet%0AEND:VEVENT%0AEND:VCALENDAR')
    //console.log(this.$route)
    let blob = new Blob([String(cal).trim()], {type: "text/calendar;charset=utf-8"})
    blob.text().then(text => {
       let str = text.replace(/(\r\n|\r|\n)/g, '%0A');
       this.calendar = "data:text/calendar;charset=utf8,"+str
       console.log("data:text/calendar;charset=utf8,"+str)
    })
    // this.calendar = URL.createObjectURL(new Blob([String(cal).trim()], {type: "text/calendar;charset=utf-8"}))
    // saveAs(blob, `vwell.ics`);
  }
},
}
</script>

<style lang="scss" scoped>
    .v-btn {
    border-radius: 6px;
    }

</style>